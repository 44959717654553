/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { fetchAuthSession } from 'aws-amplify/auth';
import { createClient } from 'graphql-ws';
import { PropsWithChildren } from 'react';
import { KeyFieldPolicies } from './keyFieldPolicies';
import { QueryPolicies } from './queryPolicies';

interface ApolloProps {
  disableHeaders?: boolean;
}

export const Apollo = ({ children, disableHeaders = false }: PropsWithChildren<ApolloProps>) => {
  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      return forward(operation);
    }
    return null;
  });

  const httpLinkUri =
    process.env.GATSBY_IS_LOCAL === 'true'
      ? process.env.GATSBY_GRAPHQL_DEV_URL
      : process.env.GATSBY_GRAPHQL_URL;
  const httpLink = createHttpLink({
    uri: httpLinkUri,
  });

  const authLink = setContext(async (_, { headers }: { headers: Record<string, unknown> }) => {
    try {
      const authSession = await fetchAuthSession();
      const idtoken = authSession?.tokens?.accessToken;
      const uid = authSession?.tokens?.idToken?.payload.preferred_username;
      return {
        headers: {
          ...headers,
          uid,
          idtoken,
        },
      };
    } catch (error) {
      return { headers };
    }
  });

  // Only create the WebSocket link in the browser
  let link = from([authLink, errorLink, httpLink]);
  if (typeof window !== 'undefined') {
    const wsImpl = typeof WebSocket !== 'undefined' ? WebSocket : null;

    const wsUrl = process.env.GATSBY_CORE_WEBSOCKET_URL;
    const wsLink = new GraphQLWsLink(
      createClient({
        url: `${wsUrl}/graphql`,
        webSocketImpl: wsImpl,
      }),
    );

    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    const splitLink = split(
      ({ query }: { query: any }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      httpLink,
    );

    link = from([authLink, errorLink, splitLink]);
  }

  const client = new ApolloClient({
    link,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        ...KeyFieldPolicies,
        Query: {
          fields: {
            ...QueryPolicies,
          },
        },
      },
    }),
    connectToDevTools: process.env.ENV === 'dev' ? true : false,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
