import { CopyIconButton } from '@components/button';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { useTheme } from '@mui/material';
import { isNil } from 'lodash';

interface DatasetsDetailsInfoPreviewProps {
  display?: string;
  name: string;
  noCopy?: boolean;
  title?: string;
  value: string | number;
  onClick?: (event) => void;
}

export const DatasetsDetailsInfoPreview = ({
  display,
  name,
  noCopy = false,
  title,
  value,
  onClick,
}: DatasetsDetailsInfoPreviewProps) => {
  const { palette } = useTheme();
  return (
    <Group alignItems="center" justifyContent="space-between" height="28px">
      <Typography variant="caption2" color={palette.text.paper}>
        {name}
      </Typography>
      <Group
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        sx={{ mr: noCopy || !value ? 0 : '-8px' }}
      >
        {isNil(value) ? (
          <Typography variant="caption2" color={palette.text.contrast}>
            -
          </Typography>
        ) : (
          <Typography
            variant="caption2"
            color={palette.text.contrast}
            sx={{
              cursor: onClick && 'pointer',
              textDecoration: onClick && 'underline',
            }}
            onClick={onClick}
            tooltip={(onClick && title) || ''}
            tooltipProps={{ variant: 'secondary' }}
          >
            {display ?? value}
          </Typography>
        )}
        {!isNil(value) && !noCopy ? <CopyIconButton value={value} /> : null}
      </Group>
    </Group>
  );
};
