import { LoadingIcon } from '@assets/icons';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { useGetDatasetInferencesQuery } from '@generated/UseGraphqlHooks';
import { CreateInferenceModal } from '@subsets/workspaces/experiments/inferences/CreateInferenceModal';
import { DatasetDetailsAccordion } from '../../DatasetDetailsAccordion';
import {
  DatasetDetailsInferencesListHeader,
  DatasetDetailsInferencesListItem,
} from './DatasetDetailsInferencesListItem';

export const DatasetDetailsInferencesList = ({
  datasetId,
  workspaceId,
}: {
  datasetId: string;
  workspaceId: string;
}) => {
  const { data, loading, refetch } = useGetDatasetInferencesQuery({
    variables: { datasetId, workspaceId },
  });
  const inferences = data?.getMLInferences || [];

  const { showModal: showCreateInferenceModal } = useModal({
    component: CreateInferenceModal,
    modalProps: { title: 'Create Inference' },
    componentProps: { workspaceId, datasetId, refetch },
  });

  return (
    <DatasetDetailsAccordion
      title="Inferences"
      details={
        loading ? (
          <LoadingIcon size={8} />
        ) : inferences.length === 0 ? (
          <Typography variant="caption2" noWrap>
            This dataset has no inferences.
          </Typography>
        ) : (
          <>
            <DatasetDetailsInferencesListHeader />
            {inferences.map((inference) => (
              <DatasetDetailsInferencesListItem
                workspaceId={workspaceId}
                {...inference}
                key={inference.inferenceId}
                refetch={refetch}
              />
            ))}
          </>
        )
      }
      count={!loading ? inferences.length : null}
      onCreate={showCreateInferenceModal}
    />
  );
};
