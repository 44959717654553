import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const Outline3DIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      {...props}
    >
      <path
        d="M21.75 15.8008V8.19922C21.7496 7.86596 21.6519 7.53865 21.4666 7.25013C21.2814 6.96161 21.0151 6.72201 20.6944 6.55538L13.3056 2.7546C12.9846 2.58781 12.6206 2.5 12.25 2.5C11.8794 2.5 11.5154 2.58781 11.1944 2.7546L3.80556 6.55538C3.48494 6.72201 3.21865 6.96161 3.03338 7.25013C2.84811 7.53865 2.75038 7.86596 2.75 8.19922V15.8008C2.75038 16.134 2.84811 16.4613 3.03338 16.7499C3.21865 17.0384 3.48494 17.278 3.80556 17.4446L11.1944 21.2454C11.5154 21.4122 11.8794 21.5 12.25 21.5C12.6206 21.5 12.9846 21.4122 13.3056 21.2454L20.6944 17.4446C21.0151 17.278 21.2814 17.0384 21.4666 16.7499C21.6519 16.4613 21.7496 16.134 21.75 15.8008Z"
        stroke={color || palette.icon.default}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="stroke-override"
      />
      <path
        d="M2 7L12 12L22 7"
        stroke={color || palette.icon.default}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="stroke-override"
      />
      <path
        d="M11.75 23V13"
        stroke={color || palette.icon.default}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="stroke-override"
      />
      <circle
        cx="11.75"
        cy="2.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="11.75"
        cy="12"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="11.75"
        cy="21.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="21.25"
        cy="7"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="2.75"
        cy="7"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="21.25"
        cy="16.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="2.75"
        cy="16.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
    </svg>
  );
});
