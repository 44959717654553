import { DownloadIcon, TrashIcon } from '@assets/icons';
import { CopyIconButton, IconButton } from '@components/button';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { MlInference } from '@generated/UseGraphqlHooks';
import { Grid, useTheme } from '@mui/material';
import { DatasetsDetailsMicroserviceStatus } from '../../DatasetsDetailsMicroserviceStatus';

export const DatasetDetailsInferencesListHeader = () => {
  const { palette } = useTheme();
  return (
    <Grid container alignItems="center">
      <Grid item xs={1.5}>
        <Typography color={palette.table.header} variant="caption2">
          Status
        </Typography>
      </Grid>
      <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography color={palette.table.header} variant="caption2">
          Id
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color={palette.table.header} variant="caption2">
          Model
        </Typography>
      </Grid>
      <Grid item xs={6} />
    </Grid>
  );
};

export const DatasetDetailsInferencesListItem = ({
  workspaceId,
  status,
  model,
  datasetId,
  inferenceId,
  refetch,
}: Partial<MlInference> & { refetch: () => void }) => {
  const handleDelete = () => {
    console.log('delete');
  };
  const handleDownload = () => {
    console.log('download');
  };
  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={1.5}>
        <Group marginLeft={1}>
          <DatasetsDetailsMicroserviceStatus
            status={status}
            microServiceName="inference"
            workspaceId={workspaceId}
            datasetId={datasetId}
            microServiceId={inferenceId}
          />
        </Group>
      </Grid>
      <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <CopyIconButton value={inferenceId} showValue tooltipProps={{ variant: 'secondary' }} />
      </Grid>
      <Grid item xs={5}>
        <Typography handleOverFlow variant="body2">
          {model}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Group justifyContent="flex-end" gap={2}>
          {status === 'success' && (
            <>
              <IconButton onClick={handleDownload} Icon={DownloadIcon} />
            </>
          )}
          <IconButton onClick={handleDelete} Icon={TrashIcon} />
        </Group>
      </Grid>
    </Grid>
  );
};
