import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const OutlineInstanceIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      {...props}
    >
      <path
        d="M3.84522 8.98209L17.9774 3.3852C19.8067 2.66072 21.4728 4.73568 20.3703 6.36534L16.5652 11.99C16.053 12.7473 16.1194 13.7555 16.7267 14.439L20.0429 18.1716C21.1889 19.4615 20.2732 21.5 18.5478 21.5H13.3229C12.7957 21.5 12.2899 21.2919 11.9154 20.9209L3.17418 12.2625C2.14907 11.2471 2.50372 9.51338 3.84522 8.98209Z"
        stroke={color || palette.icon.default}
        stroke-width="2"
        className="stroke-override"
      />
      <circle
        cx="21.5"
        cy="3.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="16.5"
        cy="13.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="21.5"
        cy="20.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="12.5"
        cy="21"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="2.5"
        cy="10.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
    </svg>
  );
});
