import { LoadingIcon } from '@assets/icons';
import { Divider, Stack } from '@mui/material';
import {
  DatasetsDetailsAnalytics,
  DatasetsDetailsAnnotations,
  DatasetsDetailsGanDatasets,
  DatasetsDetailsImages,
  DatasetsDetailsInfo,
  DatasetsDetailsPreview,
  DatasetsDetailsUmap,
  useDataset,
  useDatasets,
} from '@subsets/workspaces';
import { DatasetDetailsInferencesList, DatasetDetailsModelsList } from './DatasetDetails';

interface DatasetsDetailsProps {
  workspaceId: string;
}

export const DatasetsDetails = ({ workspaceId }: DatasetsDetailsProps) => {
  const { selectedDataset, loading } = useDatasets();
  const dataset = useDataset(selectedDataset);

  const props = {
    datasetId: selectedDataset,
    workspaceId,
  };

  return (
    <Stack
      sx={{ height: '100%', overflowY: 'auto', px: 15, py: 7 }}
      data-cy="Datasets-Details-Section"
    >
      {dataset ? (
        !loading ? (
          <>
            <DatasetsDetailsInfo dataset={dataset} {...props} />
            <Divider flexItem sx={{ my: 4 }} />
            <DatasetsDetailsImages {...props} />
            <Divider flexItem sx={{ my: 4 }} />
            <DatasetDetailsInferencesList {...props} />
            <Divider flexItem sx={{ my: 4 }} />
            <DatasetDetailsModelsList {...props} />
            <Divider flexItem sx={{ my: 4 }} />
            <DatasetsDetailsAnalytics {...props} />
            <Divider flexItem sx={{ my: 4 }} />
            <DatasetsDetailsAnnotations {...props} />
            <Divider flexItem sx={{ my: 4 }} />
            <DatasetsDetailsGanDatasets {...props} />
            <Divider flexItem sx={{ my: 4 }} />
            <DatasetsDetailsUmap {...props} />
          </>
        ) : (
          <Stack height="100%" alignItems="center" justifyContent="center">
            <LoadingIcon size={16} />
          </Stack>
        )
      ) : (
        <DatasetsDetailsPreview />
      )}
    </Stack>
  );
};
