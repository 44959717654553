import { LoadingIcon } from '@assets/icons';
import { Typography } from '@components/typography';
import { useGetDatasetModelsQuery } from '@generated/UseGraphqlHooks';
import { navigate } from 'gatsby';
import { DatasetDetailsAccordion } from '../../DatasetDetailsAccordion';
import {
  DatasetDetailsModelsListHeader,
  DatasetDetailsModelsListItem,
} from './DatasetDetailsModelsListItem';

export const DatasetDetailsModelsList = ({
  datasetId,
  workspaceId,
}: {
  datasetId: string;
  workspaceId: string;
}) => {
  const { data, loading, refetch } = useGetDatasetModelsQuery({
    variables: { datasetId, workspaceId },
  });
  const models = data?.getMLModels || [];

  return (
    <DatasetDetailsAccordion
      title="Models"
      details={
        loading ? (
          <LoadingIcon size={8} />
        ) : models.length === 0 ? (
          <Typography variant="caption2" noWrap>
            This dataset has no inferences.
          </Typography>
        ) : (
          <>
            <DatasetDetailsModelsListHeader />
            {models.map((model) => (
              <DatasetDetailsModelsListItem
                key={model.modelId}
                datasetId={datasetId}
                workspaceId={workspaceId}
                refetch={refetch}
                {...model}
              />
            ))}
          </>
        )
      }
      count={!loading ? models.length : null}
      onCreate={() => {
        navigate(`/workspaces/${workspaceId}/experiments/models/create?datasetId=${datasetId}`);
      }}
    />
  );
};
