import { AsyncButton, Button } from '@components/button';
import { Form, FormFileUpload, FormTextInput } from '@components/form';
import { Typography } from '@components/typography';
import { useCreateManagedMapMutation, useGetAnnotationMapsQuery } from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import axios from 'axios';
import { FormikProps } from 'formik';
import * as yup from 'yup';

interface NewAnnotationMapModalProps {
  onClose: () => void;
  organizationId: string;
}
interface SubmitType {
  name: string;
  description: string;
  file: Blob;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  description: yup.string(),
  file: yup.mixed().required('File is required'),
});

export const NewAnnotationMapModal = ({ onClose, organizationId }: NewAnnotationMapModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [createMap] = useCreateManagedMapMutation();
  const { refetch } = useGetAnnotationMapsQuery({
    variables: {
      organizationId,
    },
  });

  const handleFormSubmit = useAsyncNotification(
    'Successfully created the new annotation map',
    async ({ name, description, file }: SubmitType) => {
      const { data } = await createMap({
        variables: { name, organizationId, description },
      });
      const formData = new FormData();
      formData.append('key', data?.createManagedMap?.fields?.key);
      formData.append('policy', data?.createManagedMap?.fields?.policy);
      formData.append('bucket', data?.createManagedMap?.fields?.bucket);
      formData.append('X-Amz-Algorithm', data?.createManagedMap?.fields?.algorithm);
      formData.append('X-Amz-Credential', data?.createManagedMap?.fields?.credential);
      formData.append('X-Amz-Signature', data?.createManagedMap?.fields?.signature);
      formData.append('X-Amz-Date', data?.createManagedMap?.fields?.date);
      formData.append('X-Amz-Security-Token', data?.createManagedMap?.fields?.token);
      formData.append('file', file);

      await axios({
        method: 'POST',
        url: data?.createManagedMap?.url,
        data: formData,
      });
      await delay(3000);
      await refetch();
      onClose();
    },
  );

  return (
    <Form
      enableReinitialize
      initialValues={{
        name: '',
        description: '',
        file: null,
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleFormSubmit}
    >
      {({ isValid, handleSubmit, dirty, isSubmitting }: FormikProps<{ file: string }>) => (
        <Stack>
          <FormTextInput name="name" label="Name" />
          <FormFileUpload name="file" label="File" />
          <FormTextInput name="description" label="Description" multiline rows={3} />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              loading={isSubmitting}
              disabled={!(isValid && dirty && !isSubmitting)}
              onClick={handleSubmit}
            >
              Create
            </AsyncButton>
            <Button fullWidth variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};

export const NewAnnotationMapModalHelpContent = (
  <Typography variant="body2">
    During the conversion of annotations, we offer a way to map objects to specific classes. This
    can be helpful when you have several objects of one type in imagery that you want to be
    classified into a single class. For example, maybe you have imagery with Ford Focus, Honda
    Accord and Toyota Camry objects that you want to be classified as Car.
  </Typography>
);
