import { ErrorBoundary } from 'react-error-boundary';
import { DatasetsDetailsImagesSlider2DBoxesOverlay } from './DatasetsDetailsImagesSlider2DOverlay';
import { DatasetsDetailsImagesSlider3DBoxesOverlay } from './DatasetsDetailsImagesSlider3DOverlay';
import { DatasetsDetailsImagesSliderMaskOverlay } from './DatasetsDetailsImagesSliderMaskOverlay';
import { DatasetsDetailsImagesSliderSegmentationOverlay } from './DatasetsDetailsImagesSliderSegmentationOverlay';

export const DatasetsDetailsImagesSliderOverlay = ({
  opacity,
  strokeWidth,
  segmentationFill = false,
  color,
  imageAnnotations,
  imageIndex,
  state,
  renderErrors,
  setRenderErrors,
}) => {
  if (state === '2d') {
    return (
      <ErrorBoundary
        fallback={<div />}
        onError={() => {
          setRenderErrors({
            [`${imageIndex}-2d`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            ...renderErrors,
          });
        }}
        resetKeys={[imageIndex, state]}
      >
        <DatasetsDetailsImagesSlider2DBoxesOverlay
          data={imageAnnotations || null}
          strokeWidth={strokeWidth}
          color={color}
        />
      </ErrorBoundary>
    );
  }
  if (state === '3d') {
    return (
      <ErrorBoundary
        fallback={<div />}
        onError={() => {
          setRenderErrors({
            [`${imageIndex}-3d`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            ...renderErrors,
          });
        }}
        resetKeys={[imageIndex, state]}
      >
        <DatasetsDetailsImagesSlider3DBoxesOverlay
          data={imageAnnotations || null}
          color={color}
          strokeWidth={strokeWidth}
        />
      </ErrorBoundary>
    );
  }
  if (state === 'segmentation') {
    return (
      <ErrorBoundary
        fallback={<div />}
        onError={() => {
          setRenderErrors({
            [`${imageIndex}-${state}`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            [`${imageIndex}-mask`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            ...renderErrors,
          });
        }}
        resetKeys={[imageIndex, state]}
      >
        <DatasetsDetailsImagesSliderSegmentationOverlay
          color={color}
          data={imageAnnotations || null}
          strokeWidth={strokeWidth}
          opacity={opacity}
          fill={segmentationFill}
        />
      </ErrorBoundary>
    );
  }
  if (state === 'mask') {
    return (
      <ErrorBoundary
        fallback={<div />}
        onError={() => {
          setRenderErrors({
            [`${imageIndex}-${state}`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            [`${imageIndex}-segmentation`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            ...renderErrors,
          });
        }}
      >
        <DatasetsDetailsImagesSliderMaskOverlay data={imageAnnotations || null} color={color} />
      </ErrorBoundary>
    );
  }
  return null;
};
