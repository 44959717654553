import { EyeIcon, EyeOffIcon, SlidersIcon, TrashIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { ColorPicker } from '@components/form';
import { Group } from '@components/layout';
import { Typography } from '@mui/material';
import { InferenceSettings } from './DatasetsDetailsImagesSliderTypes';

type InferenceSettingsRowProps = {
  settings: InferenceSettings;
  removeRow: () => void;
  updateSettings: (settings: InferenceSettings) => void;
};

export const InferenceSettingsRow = ({
  settings,
  removeRow,
  updateSettings,
}: InferenceSettingsRowProps) => {
  const { color, visible, inferenceName } = settings;
  const updateSetting = <K extends keyof InferenceSettings>(
    key: K,
    value: InferenceSettings[K],
  ) => {
    updateSettings({ ...settings, [key]: value });
  };

  return (
    <Group alignItems="center" gap={4} py={1} maxWidth="md">
      <Typography variant="body2" sx={{ flex: 1 }}>
        {inferenceName}
      </Typography>
      <ColorPicker color={color} onChange={(newColor) => updateSetting('color', newColor)} />
      <IconButton Icon={SlidersIcon} size={24} />
      <IconButton
        Icon={visible ? EyeIcon : EyeOffIcon}
        size={24}
        onClick={() => updateSetting('visible', !visible)}
      />
      <IconButton Icon={TrashIcon} size={24} onClick={removeRow} />
    </Group>
  );
};
