import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@assets/icons';
import { Button, IconButton } from '@components/button';
import { Checkbox, Slider } from '@components/form';
import { Image } from '@components/image';
import { OptionsMenu } from '@components/menu';
import { Typography } from '@components/typography';
import {
  useEditWorkspaceThumbnailMutation,
  useGetDatasetImageLazyQuery,
} from '@generated/UseGraphqlHooks';
import { generateColorTriad } from '@helper-functions/color';
import { Box, Container, Divider, Grid, Stack, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { map } from 'lodash';
import { CSSProperties, Fragment, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import './CarouselStyles.css';
import { DatasetsDetailsImagesSliderOverlay } from './DatasetsDetailsImagesSliderOverlay';
import {
  GroundTruthSettings,
  ImageAnnotation,
  ImageAnnotations,
  InferenceSettings,
} from './DatasetsDetailsImagesSliderTypes';
import { GroundTruthSettingsRow } from './GroundTruthSettingsRow';
import { InferenceSettingsRow } from './InferenceSettingsRow';

const arrowStyles: CSSProperties = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  width: 30,
  height: 30,
  cursor: 'pointer',
};

interface DatasetsDetailsImagesSliderProps {
  previewUrls?: string[];
  imageIndex?: number | null;
  workspaceId?: string;
  datasetId?: string;
  datasetName?: string;
  loading?: boolean;
  runs?: number;
}

export const DatasetsDetailsImagesSlider = ({
  previewUrls,
  imageIndex: initialImageIndex,
  workspaceId,
  datasetId,
  datasetName,
  runs,
}: DatasetsDetailsImagesSliderProps) => {
  const { palette } = useTheme();
  const [imageIndex, setImageIndex] = useState(initialImageIndex);
  const [imageData, setImageData] = useState<{ url: string; annotations: ImageAnnotations[] }[]>(
    Array(runs),
  );
  const [groundTruthSettings, setGroundTruthSettings] = useState<GroundTruthSettings>({
    mode: '2d',
    color: '#ff0000',
    opacity: 1,
    strokeWidth: 2,
    visible: true,
  });
  const [layers, setLayers] = useState<InferenceSettings[]>([]);
  useEffect(() => console.log('imageData', imageData), [imageData]);
  const [getDatasetImage] = useGetDatasetImageLazyQuery();
  useEffect(() => {
    const loadImageData = async (index: number) => {
      const {
        data: {
          getDatasetAnnotation: {
            data: [imageData],
          },
          getDatasetImage: {
            data: [imageUrl],
          },
        },
      } = await getDatasetImage({
        variables: { workspaceId, datasetId, run: index },
      });
      console.log(imageUrl, index);
      if (!imageUrl) {
        return;
      }
      setImageData((old) => {
        const updated = [...old];
        updated[index] = {
          url: imageUrl,
          annotations: imageData
            ? (JSON.parse(imageData) as { annotations: ImageAnnotations[] }).annotations
            : [],
        };
        return updated;
      });
      // setImageAnnotations((old) => ({
      //   ...old,
      //   [index]: {
      //     data: imageData
      //       ? (JSON.parse(imageData) as { annotations: ImageAnnotations[] }).annotations
      //       : [],
      //     url: imageUrl,
      //   },
      // }));
    };
    // const loadedImageData = Object.keys(imageData);
    if (!imageData[imageIndex]) {
      //loadedImageData.includes(imageIndex.toString())) {
      void loadImageData(imageIndex);
    }
    // if (!loadedImageData.includes((imageIndex + 1).toString())) {
    if (imageIndex < runs - 1 && !imageData[imageIndex + 1]) {
      void loadImageData(imageIndex + 1);
    }
    // (imageIndex > 0 && !loadedImageData.includes((imageIndex - 1).toString())) {
    if (imageIndex > 0 && !imageData[imageIndex - 1]) {
      void loadImageData(imageIndex - 1);
    }
  }, [imageIndex]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <Stack data-cy="Datasets-Details-Images-Slider" sx={{ height: '100%', gap: 2 }}>
      <Typography variant="subtitle1">{datasetName}</Typography>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators
        selectedItem={imageIndex}
        onChange={(number) => setImageIndex(number)}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <IconButton
              data-cy="Datasets-Details-Images-Slider-Left-Icon"
              onClick={onClickHandler}
              tooltip={label}
              style={{ ...arrowStyles, left: 15 }}
              Icon={ChevronLeftIcon}
            />
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <IconButton
              onClick={onClickHandler}
              tooltip={label}
              data-cy="Datasets-Details-Images-Slider-Right-Icon"
              style={{ ...arrowStyles, right: 15 }}
              Icon={ChevronRightIcon}
            />
          )
        }
      >
        {imageData.map((image, idx) => (
          <Fragment key={image?.url}>
            <Image
              url={image?.url}
              overlaySVG={
                groundTruthSettings.visible && (
                  <DatasetsDetailsImagesSliderOverlay
                    {...groundTruthSettings}
                    imageAnnotations={image?.annotations}
                    imageIndex={idx}
                    state={groundTruthSettings.mode}
                    renderErrors={null}
                    setRenderErrors={null}
                  />
                )
              }
              overlayStyle={
                image?.annotations &&
                groundTruthSettings.mode === 'segmentation' &&
                groundTruthSettings.segmentationMask // &&
                  ? // !renderErrors[`${imageIndex}-mask`]
                    { backgroundColor: 'black' }
                  : {}
              }
            />
            {/* <OptionsMenu
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
                borderRadius: '20px',
                backgroundColor: palette.background.default,
              }}
              items={[{ label: 'Set workspace thumbnail', onClick: () => handleSetThumbnail(url) }]}
              menuProps={{ transformOrigin: { vertical: 'top', horizontal: 'right' } }}
            /> */}
          </Fragment>
        ))}
      </Carousel>
      <Container maxWidth="md" sx={{ mx: 'auto' }}>
        <GroundTruthSettingsRow
          settings={groundTruthSettings}
          updateSettings={setGroundTruthSettings}
        />
        <Divider />
        {layers.map((layer, index) => (
          <InferenceSettingsRow
            key={index}
            settings={layer}
            removeRow={() => {
              const nextLayers = layers.filter((_, i) => i !== index);
              setLayers(nextLayers);
            }}
            updateSettings={(newSettings) => {
              const nextLayers = [...layers];
              nextLayers[index] = newSettings;
              setLayers(nextLayers);
            }}
          />
        ))}
        {layers.length < 2 && (
          <Button
            startIcon={PlusIcon}
            disabled={layers.length >= 2}
            onClick={() =>
              setLayers((currentLayers) => [
                ...currentLayers,
                {
                  color: generateColorTriad(groundTruthSettings.color)[currentLayers.length],
                  opacity: 1,
                  strokeWidth: 2,
                  visible: true,
                  inferenceName: 'Inference thing',
                },
              ])
            }
            sx={{ my: 1.25 }}
          >
            Add inference layer
          </Button>
        )}
      </Container>
      {/* <ImageSelector
          datasetId={datasetId}
          selected={imageIndex}
          workspaceId={workspaceId}
          onSelect={(newIndex) => setImageIndex(newIndex)}
        /> */}
      {/* 
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => setLayers([{}, {}])}>Ground truth</MenuItem>
        </Menu> */}
    </Stack>
  );
};

export const OldDatasetsDetailsImagesSlider = ({
  previewUrls,
  imageIndex: initialImageIndex,
  loading,
  workspaceId,
  datasetId,
}: DatasetsDetailsImagesSliderProps) => {
  const [imageIndex, setImageIndex] = useState(initialImageIndex);
  const [imageAnnotations, setImageAnnotations] = useState<
    Record<number, { data: ImageAnnotations[]; url: string }>
  >({});
  const [renderErrors, setRenderErrors] = useState({});

  const [getDatasetImage] = useGetDatasetImageLazyQuery();

  useEffect(() => {
    const loadImageData = async (index: number) => {
      const {
        data: {
          getDatasetAnnotation: {
            data: [imageData],
          },
          getDatasetImage: {
            data: [imageUrl],
          },
        },
      } = await getDatasetImage({
        variables: { workspaceId, datasetId, run: index },
      });
      setImageAnnotations((old) => ({
        ...old,
        [index]: {
          data: imageData
            ? (JSON.parse(imageData) as { annotations: ImageAnnotations[] }).annotations
            : [],
          url: imageUrl,
        },
      }));
    };
    const loadedAnnotations = Object.keys(imageAnnotations);
    if (!loadedAnnotations.includes(imageIndex.toString())) {
      void loadImageData(imageIndex);
    }
    if (
      previewUrls.length > imageIndex + 1 &&
      !loadedAnnotations.includes((imageIndex + 1).toString())
    ) {
      void loadImageData(imageIndex + 1);
    }
  }, [imageIndex]);

  const { useAsyncNotification } = useNotifications();
  const { palette } = useTheme();
  const [editWorkspaceThumbnail] = useEditWorkspaceThumbnailMutation();

  const [state, setState] = useState<ImageAnnotation | 'image'>('image');
  const [segmentationFill, setSegmentationFill] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [opacity, setOpacity] = useState(0.5);
  const showOpacitySlider =
    state === 'segmentation' && imageAnnotations[imageIndex]?.data.length !== 0;
  const showStrokeSlider =
    (state === '2d' || state === '3d' || state === 'segmentation') &&
    imageAnnotations[imageIndex]?.data?.length !== 0;

  useEffect(() => {
    const annotations = imageAnnotations[imageIndex]?.data || null;
    if (!annotations) {
      return;
    }
    if (annotations && !annotations?.length) {
      setRenderErrors((oldErrors) => ({
        [`${imageIndex}-2d`]: { message: 'No annotations found for this image', type: 'warning' },
        [`${imageIndex}-3d`]: { message: 'No annotations found for this image', type: 'warning' },
        [`${imageIndex}-segmentation`]: {
          message: 'No annotations found for this image',
          type: 'warning',
        },
        [`${imageIndex}-mask`]: { message: 'No annotations found for this image', type: 'warning' },
        ...oldErrors,
      }));
      return;
    }
    if (annotations?.every((annotation) => !annotation.bbox || annotation.bbox.length === 0)) {
      setRenderErrors((oldErrors) => ({
        [`${imageIndex}-2d`]: {
          message: 'No bounding box data found for this image',
          type: 'warning',
        },
        ...oldErrors,
      }));
    }
    if (annotations?.every((annotation) => !annotation.bbox3d || annotation.bbox3d.length === 0)) {
      setRenderErrors((oldErrors) => ({
        [`${imageIndex}-3d`]: {
          message: 'No 3d bounding box data found for this image',
          type: 'warning',
        },
        ...oldErrors,
      }));
    }
    if (
      annotations?.every(
        (annotation) => !annotation.segmentation || annotation.segmentation.length === 0,
      )
    ) {
      setRenderErrors((oldErrors) => ({
        [`${imageIndex}-segmentation`]: {
          message: 'No segmentation data found for this image',
          type: 'warning',
        },
        [`${imageIndex}-mask`]: {
          message: 'No mask data found for this image',
          type: 'warning',
        },
        ...oldErrors,
      }));
    }
  }, [imageAnnotations, imageIndex]);

  const handleSetThumbnail = useAsyncNotification(
    'You have successfully changed the workspace thumbnail',
    async (url: string) => {
      await editWorkspaceThumbnail({
        variables: {
          workspaceId,
          thumbnail: url,
        },
      });
    },
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      data-cy="Datasets-Details-Images-Slider"
      sx={{ height: '100%' }}
    >
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators
        selectedItem={imageIndex}
        onChange={(number) => setImageIndex(number)}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <IconButton
              data-cy="Datasets-Details-Images-Slider-Left-Icon"
              onClick={onClickHandler}
              tooltip={label}
              style={{ ...arrowStyles, left: 15 }}
              Icon={ChevronLeftIcon}
            />
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <IconButton
              onClick={onClickHandler}
              tooltip={label}
              data-cy="Datasets-Details-Images-Slider-Right-Icon"
              style={{ ...arrowStyles, right: 15 }}
              Icon={ChevronRightIcon}
            />
          )
        }
      >
        {map(previewUrls, (url, idx) => (
          <Fragment key={url}>
            <Image
              url={imageAnnotations[idx]?.url}
              // overlaySVG={
              //   <DatasetsDetailsImagesSliderOverlay
              //     imageAnnotations={imageAnnotations}
              //     imageIndex={idx}
              //     strokeWidth={strokeWidth}
              //     opacity={opacity}
              //     segmentationFill={segmentationFill}
              //     state={state}
              //     renderErrors={renderErrors}
              //     setRenderErrors={setRenderErrors}
              //   />
              // }
              // overlayStyle={
              //   imageAnnotations[imageIndex]?.data &&
              //   state === 'mask' &&
              //   !renderErrors[`${imageIndex}-mask`]
              //     ? { backgroundColor: 'black' }
              //     : {}
              // }
            />
            <OptionsMenu
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
                borderRadius: '20px',
                backgroundColor: palette.background.default,
              }}
              items={[{ label: 'Set workspace thumbnail', onClick: () => handleSetThumbnail(url) }]}
              menuProps={{ transformOrigin: { vertical: 'top', horizontal: 'right' } }}
            />
          </Fragment>
        ))}
      </Carousel>
      {imageAnnotations[imageIndex]?.data && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          gap={8}
          marginTop={4}
        >
          <Box display="flex" flexDirection="column" gap={4}>
            <Box display="flex" flexWrap="wrap" gap={1} paddingTop="4px">
              <Typography
                sx={{
                  textDecoration: `${state === 'image' ? 'none' : 'underline'}`,
                  color: `${state === 'image' ? palette.primary.main : 'inherit'}`,
                  '&:hover': { cursor: 'pointer' },
                }}
                onClick={() => setState('image')}
              >
                Image
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography
                sx={{
                  textDecoration: `${state === '2d' ? 'none' : 'underline'}`,
                  color: `${state === '2d' ? palette.primary.main : 'inherit'}`,
                  '&:hover': { cursor: 'pointer' },
                }}
                onClick={() => setState('2d')}
              >
                2D Bounding Box
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography
                sx={{
                  textDecoration: `${state === '3d' ? 'none' : 'underline'}`,
                  color: `${state === '3d' ? palette.primary.main : 'inherit'}`,
                  '&:hover': { cursor: 'pointer' },
                }}
                onClick={() => setState('3d')}
              >
                3D Bounding Box
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography
                sx={{
                  textDecoration: `${state === 'segmentation' ? 'none' : 'underline'}`,
                  color: `${state === 'segmentation' ? palette.primary.main : 'inherit'}`,
                  '&:hover': { cursor: 'pointer' },
                }}
                onClick={() => setState('segmentation')}
              >
                Instance Segmentation
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography
                sx={{
                  textDecoration: `${state === 'mask' ? 'none' : 'underline'}`,
                  color: `${state === 'mask' ? palette.primary.main : 'inherit'}`,
                  '&:hover': { cursor: 'pointer' },
                }}
                onClick={() => setState('mask')}
              >
                Instance Mask
              </Typography>
            </Box>
            {renderErrors[`${imageIndex}-${state}`] && (
              <Typography
                variant="body2"
                color={
                  renderErrors[`${imageIndex}-${state}`].type === 'error'
                    ? palette.error.main
                    : palette.warning.main
                }
              >
                {renderErrors[`${imageIndex}-${state}`].message}
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            gap={4}
            alignItems="flex-start"
            maxWidth="500px"
            minWidth="300px"
          >
            <Grid container flex="1">
              <Grid
                container
                item
                columnSpacing={4}
                xs={12}
                sx={{
                  visibility: showStrokeSlider ? 'inherit' : 'hidden',
                  alignItems: 'center',
                }}
              >
                <Grid item xs={5} textAlign="end">
                  <Typography>Stroke Width {strokeWidth}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Slider
                    sx={{ width: '100%' }}
                    max={6}
                    value={strokeWidth}
                    disabled={renderErrors[`${imageIndex}-${state}`] !== undefined}
                    onChange={(_e, v) => {
                      const newValue = typeof v === 'object' ? v[0] : v;
                      setStrokeWidth(newValue);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                columnSpacing={4}
                xs={12}
                sx={{
                  visibility: showOpacitySlider ? 'inherit' : 'hidden',
                  alignItems: 'center',
                }}
              >
                <Grid item xs={5} textAlign="end">
                  <Typography>Opacity {Math.floor(opacity * 100)}%</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Slider
                    sx={{ width: '100%' }}
                    max={1}
                    value={opacity}
                    step={0.01}
                    disabled={
                      !segmentationFill || renderErrors[`${imageIndex}-${state}`] !== undefined
                    }
                    onChange={(_e, v) => {
                      const newValue = typeof v === 'object' ? v[0] : v;
                      setOpacity(newValue);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                columnSpacing={4}
                xs={12}
                pt={1}
                visibility={showOpacitySlider ? 'inherit' : 'hidden'}
                sx={{
                  alignItems: 'center',
                }}
              >
                <Grid item xs={5} textAlign="end">
                  <Typography>Fill</Typography>
                </Grid>
                <Grid item>
                  <Checkbox
                    checked={segmentationFill}
                    disabled={renderErrors[`${imageIndex}-${state}`] !== undefined}
                    onClick={() => setSegmentationFill(!segmentationFill)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};
