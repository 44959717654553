import { AsyncButton, Button } from '@components/button';
import { Form, FormSelect } from '@components/form';
import {
  useCreateAnnotationMutation,
  useGetAnnotationFormatsQuery,
  useGetAnnotationMapsQuery,
} from '@generated/UseGraphqlHooks';
import { MenuItem, Stack, Typography } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { find, includes, values as lodashValues, map, size, sortBy } from 'lodash';
import * as yup from 'yup';

const formInitialValues = {
  format: '',
  mapping: '',
};

const validationSchema = yup.object({
  format: yup.string().required('Format is required'),
  mapping: yup.string().required('Map is required'),
});

type DatasetsDetailsAnnotationsCreateAnnotationProps = {
  datasetId: string;
  workspaceId: string;
  onClose: VoidFunction;
  refetch: VoidFunction;
};

export const DatasetsDetailsAnnotationsCreateAnnotation = ({
  datasetId,
  workspaceId,
  onClose,
  refetch,
}: DatasetsDetailsAnnotationsCreateAnnotationProps) => {
  const { useAsyncNotification } = useNotifications();
  const [createAnnotation] = useCreateAnnotationMutation();

  const { data: annotationFormats, loading: annotationFormatsLoading } =
    useGetAnnotationFormatsQuery();
  const { data: annotationMaps, loading: annotationMapsLoading } = useGetAnnotationMapsQuery({
    variables: { workspaceId },
  });
  const sortedAnnotationFormats =
    !annotationFormatsLoading &&
    sortBy(annotationFormats?.getAnnotationFormats, (format) => format);
  const sortedAnnotationMaps =
    !annotationMapsLoading &&
    sortBy(annotationMaps?.getAnnotationMaps, (annotationMap) => annotationMap);

  const noMapping = size(annotationMaps?.getAnnotationMaps) === 0;

  const handleSaveButton = useAsyncNotification(
    'Successfully created annotation.',
    async ({ format, mapping }: { format: string; mapping: string }) => {
      onClose();
      await createAnnotation({
        variables: {
          datasetId,
          workspaceId,
          format,
          mapId: mapping,
        },
      });
      void refetch();
    },
  );

  const mappingName = (id: string) => {
    const mapping = find(sortedAnnotationMaps, { mapId: id });
    return mapping?.name;
  };

  return (
    <Form
      validateOnBlur={false}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSaveButton}
    >
      {({ isValid, dirty, values }) => {
        const checkIfAllValuesAreFilled = includes(
          map(lodashValues(values) || [], (value: string) => size(value)),
          0,
        );
        return (
          <Stack>
            <FormSelect name="format" label="Format" disabled={annotationFormatsLoading}>
              {(sortedAnnotationFormats || []).map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </FormSelect>
            <FormSelect name="mapping" label="Mapping" disabled={annotationMapsLoading}>
              {(sortedAnnotationMaps || []).map((map) => (
                <MenuItem value={map.mapId}>{mappingName(map.mapId)}</MenuItem>
              ))}
            </FormSelect>
            <Stack gap={4}>
              <AsyncButton
                disabled={noMapping || !(isValid && dirty) || checkIfAllValuesAreFilled}
                fullWidth
                type="submit"
                variant="primary"
                data-cy="Annotation-Create-Button"
              >
                Create
              </AsyncButton>
              <Button
                fullWidth
                variant="secondary"
                onClick={onClose}
                data-cy="Datasets-Details-Annotation-Cancel-Button"
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        );
      }}
    </Form>
  );
};

export const DatasetsDetailsAnnotationsCreateAnnotationHelpContent = (
  <Stack gap={1}>
    <Typography variant="body2">
      Annotations are a description of what is in the imagery, usually in a format that a machine
      learning algorithm can ingest. The annotations generated are a proprietary format that needs
      to be converted before being ingested by a machine learning algorithm. For this reason, we
      provide a service to convert our annotations to several common formats.
    </Typography>
    <Typography variant="body2">
      During the conversion of annotations, we offer a way to map objects to specific classes. This
      can be helpful when you have several objects of one type in imagery that you want to be
      classified into a single class.
    </Typography>
  </Stack>
);
